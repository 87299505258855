.bborder,
.bborder-flex {
    /* border-bottom: 2px solid black; */
    position: relative;
}

.bborder-flex {
    display: flex;
}

.bborder-flex_1{
    padding-top: 4px;
}

.personnel-row {
    height: 19px !important;
}



.table_2 {
    margin: 0;
    padding: 0;
    height: unset;
    width: 100%;
}


.table_2 tr {
    height: 10px !important;
}

.personnel_1 {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    padding-top: 61px;
}

.personnel-title {
    width: 223px;

}

.noborder {
    border: none !important;
}

.btitle {
    background-color: white;
    padding: 5px;
    /* position: absolute; */
    /* margin: 0 0px 0 -3px; */
    font-weight: 700;
}

.btitle_1 {
    background-color: white;
    /* padding: 5px; */
    /* position: absolute; */
    margin: 0 6px 0 0px;
    font-weight: 700;
}

.bpadd {
    padding-left: 30px;
}

.bheader {
    font-weight: 700;
    text-align: center;
    font-size: 5mm;
}

.bcontent {
    margin: 5px 0px 0 6px;
}

.bcontent_1 {
    margin: 0px 0px 0 0px;
}

.bflex {
    position: relative;
    display: inline-flex;
}

.bleft {
    border-right: 2px solid black;
    padding-right: 10px;
}

.bright_1 {
    padding-left: 50px;
    padding-right: 50px;
}

.bold {
    font-weight: 700;
}

.text-block {
    border: 2px solid gray;
    width: calc(100% - 20px);
    min-height: 40px;
    margin-left: 20px;
}

.w100 {
    width: 100%;
}

.w40 {
    width: 40%;
}

.w20 {
    width: 20%;
}

.borderBottom {
    border-bottom: 2px solid black;
}