
.table_6,
.table_6 tr,
.table_6 td {
    border: 2px solid;

}

.table_6 {
    margin: 8mm;
    padding: 0;
    /* height: 100%; */
    width: 94%;
    border-collapse: collapse;
}

.table_6 tr {
    height: 3ex;
}

.table_6 td {
    padding: 2ex;
    font-size: 17px;
    text-align: center;
}

.table_6 td:first-child {
    width: 15mm;
}

.table_6 td:nth-of-type(2) {
    width: 48mm;
}

.htitle {
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: -22px;
    margin-top: 10px;
}