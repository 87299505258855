/* .evacuation-table-container{
    height: calc(100vh - 150px);
}

@media print {
    .evacuation-table-container{
        height: 2000 !important;
    }  
} 
  @media print {
    body {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
      }
      
      .MuiDataGrid-main,
      .MuiDataGrid-virtualScroller {
        overflow: visible !important;
        height: 5000px !important;
      }
    } */

.MuiDataGrid-columnsManagementRow .Mui-disabled {
  display: none !important;
}
