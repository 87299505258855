
.TWpage {
  margin: 0;
  padding: 4px;
  min-height: 285mm;
  width: 200mm;
  page-break-after: always;
  padding-top: 6mm;
  font-size: 9.8pt;
}
.TWpage:nth-last-child(2) {
  page-break-after: auto !important;
  overflow: hidden !important;
}
.TWtable,
.TWmain-table,
.TWblock-table {
  margin: 0;
  padding: 0;
  /* height: 100%; */
  width: 100%;
  margin-top: -4px;
}

.TWmain-table {
  width: 100%;
  padding-left: 3mm;
}

.TWblock-table {
  margin-left: -4px;
}

.TWhalf {
  width: 47%;
  vertical-align: top;
}

.TWbborder,
.TWbborder-flex {
  border-bottom: 1px solid black;
  position: relative;
}

.TWbborder-flex {
  display: flex;
  min-height: 3.1mm;
}

.TWnoborder {
  border: none !important;
}

.TWbtitle {
  background-color: white;
  padding: 0px 5px 0 5px;
  position: absolute;
  margin: 0;
  margin-left: -2px;
  white-space: nowrap;
}


.TWbheader {
  font-weight: 700;
  /* text-align: center; */
}

.TWbcontent,
.TWbcontent-Exp {
  /* margin: 4px 0px 0 6px; */
  /* width: 100%; */
  position: absolute;
  /* white-space: nowrap; */
  margin-left: 2mm;
  margin-top: -0.5mm;
}

.TWbcontent-Exp {
  position: relative;
}

.TWbright {
  padding-left: 10px;
  padding-top: 2px;
  border: 2px solid black;
  vertical-align: top;
}

.TWtextCenter {
  text-align: center;
}

.TWform-number {
  position: absolute;
  margin-left: 556px;
  z-index: 1;
  margin-top: -17px;
  font-size: 9pt;
}

.TWhide {
  display: none;
}





.TWhalf2 {
  width: 63%;
  vertical-align: top;
}

.TWbright2 {
  padding-left: 10px;
  padding-top: 2px;
  /* border: 2px solid black; */
  vertical-align: top;
}

.TWinner-table {
  width: 100%;
}

.TWbtitle-big {
  font-size: 14pt;
  font-weight: 600;
}


.TWinner-table td {
  height: 6mm;
  padding: 1mm;
}

.TWinner-table,
.TWinner-table th,
.TWinner-table td {
  border: 1px solid black;
  border-collapse: collapse;
  vertical-align: top;
}

.TWbig {
  height: 4mm;
}

.TWnoborder-table,
.TWnoborder-table th,
.TWnoborder-table td {
  border: none !important;
}


.TWinner-table {
  border: solid #000 !important;
  border-width: 1px 0 0 1px !important;
}

.TWinner-table th,
.TWinner-table td {
  border: solid #000 !important;
  border-width: 0 1px 1px 0 !important;
}

.TWtable-no-border,
.TWtable-no-border td,
.TWtable-no-border div {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}