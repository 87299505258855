@page {
    size: A4;
    margin: 0;
}

html {
    width: 100%;
    height: 100%;
    background-color: white;
}

.print {
    display: none !important;
}

@media print {

    html,
    body {
        font-size: 10.2pt;
        height: auto !important;
        width: auto !important;
        background-color: white;
        /* overflow: hidden !important; */
    }

    /* .MuiDialog-root { overflow: hidden !important; } */

    *,
    *:before,
    *:after {
        color: #000;
        box-shadow: none;
        text-shadow: none;
    }

    .no-print,
    .no-print * {
        display: none !important;
    }

    .print {
        display: block !important;
    }

    .page {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        page-break-after: always;
    }
    .page:nth-last-child(2) {
        page-break-after: auto;
        overflow: hidden;
    }
    .page:last-child {
        page-break-after: auto;
        overflow: hidden;
    }
    .page-nobreak {
        height: 290mm;
        width: 210mm;
        margin: 0;
        padding: 0;
        page-break-after: avoid !important;
        overflow: hidden;
    }
    
}



.table {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.table tr {
    height: 3ex;
}

.half {
    width: 50%;
}

.bborder,
.bborder2,
.bborder-flex {
    /* border-bottom: 2px solid black; */
    position: relative;
}

.bborder2 {
    border-bottom: 2px solid black;
}

.bborder-flex {
    display: flex;
}

.noborder {
    border: none !important;
}

.btitle,
.btitle-w {
    background-color: white;
    padding: 5px;
    /* position: absolute; */
    margin: 0 0px 0 -3px;
}

.btitle-w {
    margin: 4px 0px -8px -3px;
}

.bpadd {
    padding-left: 30px;
}

.bheader {
    font-weight: 700;
    text-align: center;
}

.bcontent {
    margin: 5px 0px 0 6px;
}

.bflex {
    position: relative;
    display: inline-flex;
}

.bleft {
    border-right: 2px solid black;
    padding-right: 10px;
}

.bright {
    padding-left: 10px;
}

.bold {
    font-weight: 700;
}

.text-block {
    border: 2px solid gray;
    width: calc(100% - 20px);
    min-height: 40px;
    margin-left: 20px;
}

.w100 {
    width: 100%;
}

.w40 {
    width: 40%;
}

.w20 {
    width: 20%;
}


.phone {
    margin-left: 80px;
}


.borderBottom {
    border-bottom: 2px solid black;
}

.t1 {
    display: inline-flex;
    width: 50%;
    text-align: right;
    justify-content: center;
}

.tsquare {
    width: 20px;
    height: 20px;
    border: 2px solid black;
    margin-left: 10px;
}

.t2 {
    display: inline-flex;
    /* width: 50%; */
    text-align: left;
}