.page {
    height: 290mm;
    width: 210mm;
}

.page-container {
    height: 290mm;
    width: 210mm;
    position: relative;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 0.01em;
}

.b1 {
    left: 1mm;
    text-align: center;
    width: 90mm;
    background-color: #FFF;
    z-index: 1000000;
    margin-top: 15mm;
    display: block;
    position: absolute;
}

.b1-text {
    margin-top: 6mm;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.05;
}

.b1-bottom {
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
}

.b2 {
    left: 117mm;
    top: 23mm;
    text-align: center;
    height: 90mm;
    width: 95mm;
    background-color: #FFF;
    z-index: 1000000;
    margin-top: 70px;
    display: none;
    position: absolute;
}

.b3 {
    left: 16mm;
    top: 88mm;
    text-align: center;
    width: 177mm;
    background-color: #FFF;
    z-index: 1000000;
    margin-top: 23mm;
    display: block;
    position: absolute;
}

.b3-title-1 {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.b3-title-2 {
    margin-top: 4mm;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.b3-text {
    margin-top: 14mm;
    text-align: justify;
    font-size: 18px;
    line-height: 1.3;
    text-indent: 13mm;
}

.b3-sign {
    margin-top: 14mm;
    text-align: center;
    font-size: 18px;
    line-height: 1.3;
}

.b3-sign-2 {
    margin-left: 26mm;
    text-align: left;
    font-size: 18px;
}

.b3-sign-name {
    margin-top: 3mm;
    text-align: right;
    font-size: 18px;
    margin-right: 9mm;
}

.b3-sign-mp {
    margin-top: 30px;
    text-align: left;
    font-size: 18px;
    margin-left: 11mm;
}



.b4 {
    left: 21mm;
    top: 10mm;
    text-align: center;
    height: 90mm;
    width: 171mm;
    background-color: #FFF;
    z-index: 1000000;
    margin-top: 70px;
    display: block;
    position: absolute;
}

.b4-text {
    margin-top: 7mm;
    margin-bottom: 90px;
    text-align: justify;
    font-size: 18px;
    line-height: 1.32;
}

.b4-text-line-1,
.b4-text-line-2 {
    border-top: 2px solid #000;
   
    text-align: center;
    font-size: 14px;
}

.b4-text-line-2 {
    margin-top: 10mm;
    margin-bottom: 80px;
}